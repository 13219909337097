import '../../Assets/css/Footer.css'
import { Row, Col } from 'reactstrap'

const Footer = (props) => {
    return (
        <div className="footer fixed-bottom pt-3 pb-3" style={{ overflow: "hidden" }}>
            <Row>
                <Col xs="4" className="footer-text ps-4" >
                    {props.name}
                </Col>
                <Col xs="8" className="text-end footer-text pe-4">
                    Mobyte Ltd - Copyright © {new Date().getFullYear()}.
                </Col>
            </Row>
        </div>
    );
}

export default Footer