import { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import Routes from './Components/Shared/Routes'
import LoadSpinner from './Components/Shared/Loading'

const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const name = accounts[0]?.name;
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance.handleRedirectPromise()
          .then((tokenResponse) => {
            if (!tokenResponse) {
              const account = instance.getAllAccounts();
              if (account.length === 0) {
                instance.loginRedirect();
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    }, 500);
  });

  return (
    <>
      <AuthenticatedTemplate>
        <Routes name={name} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadSpinner />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;


