import { utils, writeFile } from "xlsx";
import { FormatDecimal } from "../Functions/NumberFormatter";

const ExportExcel = (data, filename, outputKeys) => {
    const rows = data.map((row) => {
        const filteredData = Object.fromEntries(
            Object.entries(row)
                .filter(([key]) => outputKeys.includes(key))
                .sort(
                    ([keyA], [keyB]) =>
                        outputKeys.indexOf(keyA) - outputKeys.indexOf(keyB)
                )
        );
        return filteredData;
    });

    let dateFields = [];
    outputKeys.forEach((item) => {
        let field = item.toLowerCase();
        if (field.includes("date") || field.includes("invoiced")) {
            dateFields.push(item);
        }
    });

    let currencyFields = [];
    outputKeys.forEach((item) => {
        let field = item.toLowerCase();
        if (
            field.includes("quoteprice") ||
            field.includes("estimatedprofit") ||
            field.includes("totalpoamount") ||
            field.includes("actualprofit")
        ) {
            currencyFields.push(item);
        }
    });

    rows.forEach((item) => {
        currencyFields.forEach((currencyField) => {
            item[currencyField] = FormatDecimal(item[currencyField]);
        });
    });

    rows.forEach((item) => {
        dateFields.forEach((dateField) => {
            if (
                dateField === "expDate" ||
                dateField === "expiryDate" ||
                dateField === "defExpiryDate"
            ) {
                let dt = new Date(item[dateField]);
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                item[dateField] = year + "/" + ("0" + month).slice(-2);
                // item[dateField] = new Date(item[dateField]);
            } else if (item[dateField] === null) {
                item[dateField] = "";
            } else {
                item[dateField] = new Date(item[dateField]);
            }
        });
    });

    // Create a new workbook
    const workbook = utils.book_new();

    // Create a worksheet from the data
    const worksheet = utils.json_to_sheet(rows, {
        cellDates: true,
        dateNF: "yyyy-mm-dd",
    });

    // Set the desired column width
    const columnWidth = 20; // Set your desired width in characters
    const numColumns = Object.keys(data[0]).length; // Get the number of columns

    // Create an array for column widths and fill it with the desired width
    worksheet["!cols"] = Array(numColumns).fill({ wch: columnWidth });

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a file
    writeFile(workbook, `${filename}.xlsx`, { compression: true });
};

export { ExportExcel };
