import { useEffect, useState, useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import '../Assets/css/Main.css'
import base from '../Services/BaseService'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

const FileModal = ({ showModalForm, closeModal, tokenRefresh }) => {
    const [showModal, setShowModal] = useState(showModalForm)
    const [previewClick, setPreviewClick] = useState(false)
    const [previewData, setPreviewData] = useState([]);
    const [importColDef, setImportColDef] = useState([]);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);

    const toggleModal = () => {
        closeModal()
        setShowModal(!showModal);
    }

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
    }, [formatNumber]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setImportColDef([

            {
                headerName: "Account",
                field: "accountId",
                suppressMovable: true,
                maxWidth: 95
            },
            {
                headerName: "Date",
                field: "transactionDate",
                suppressMovable: true,
                maxWidth: 95,
                valueGetter: (params) => {
                    const value = params.data.transactionDate;
                    if (typeof value === "string" && value.includes("T")) {
                        return value.split("T")[0];
                    }
                    return value;
                },
            },
            {
                headerName: "Description",
                field: "transactionDescription",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Category",
                field: "categoryId",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Amount",
                field: "value",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 125,
                valueFormatter: currencyFormatter
            },
        ]
        );

    }, [currencyFormatter]);

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const UploadClick = async () => {
        await tokenRefresh()
        base.add("FileUpload/UploadCommit", "").then(() => {
            toggleModal()
        }).catch(() => {
            console.log("Error acquiring token")
        })
    };

    const handleFileInput = async (e) => {
        // handle validations
        const data = new FormData();
        await tokenRefresh()
        const file = e.target.files[0];
        data.append('file', file);
        base.add("FileUpload/Add", data).then((res) => {
            setPreviewData(res.data);
            setPreviewClick(!previewClick);
        });;
    };

    return (
        <>
            <Modal isOpen={showModal} size="xl" toggle={() => setShowModal(!showModal)} backdrop="static">
                <ModalHeader className="page-header" toggle={() => setShowModal(!showModal)}><div className="md-header">Import Data</div></ModalHeader>

                <ModalBody>
                    {previewClick ?
                        <div className="ag-theme-balham" style={{ height: "390px", width: "100%" }} >
                            <AgGridReact
                                rowData={previewData}
                                columnDefs={importColDef}
                                overlayNoRowsTemplate="No Records Found"
                                defaultColDef={gridDefaultColDef}
                                pagination={true}
                                paginationPageSizeSelector={[10, 20, 50, 100]}
                                paginationPageSize={10}>
                            </AgGridReact>
                        </div>
                        :
                        <form>
                            <input className='input-file'
                                type="file"
                                onChange={handleFileInput}
                            />
                        </form>
                    }
                </ModalBody>
                <ModalFooter>
                    {previewClick &&
                        <Button color="secondary" size="sm" className="btn-text" onClick={() => UploadClick()}>Upload</Button>
                    }
                </ModalFooter>

            </Modal>
        </>
    )
}

export default FileModal
