import { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { ReactComponent as Moneyhub } from '../Assets/icons/moneyhub.svg'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import FileModal from './FileModal';
import LoadSpinner from './Shared/ComponentLoading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import { Bar } from 'react-chartjs-2';
import { IoCloudUploadOutline, IoRefreshOutline, IoCardOutline, IoGridOutline, IoWalletOutline, IoBarChartOutline, IoHammerOutline, IoAddCircle, IoRemoveCircle } from 'react-icons/io5';

import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement, LineElement, PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement, PointElement, LineElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);


const Dashboard = () => {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [fieldData, setFieldData] = useState({});
    const [budgetData, setBudgetData] = useState([]);
    const [expData, setExpData] = useState([]);
    const [investment, setInvestment] = useState([]);
    const [bankValues, setBankValues] = useState([]);
    const [bankLabels, setBankLabels] = useState([]);
    const [transColDef, setTransColDef] = useState([]);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(true);
    const [refreshPage, setRefreshPage] = useState(false);
    const { instance, accounts } = useMsal();

    const options = {
        style: 'currency',
        currency: 'GBP'
    };

    const data = {
        labels: bankLabels,
        datasets: [
            {
                label: 'Balance',
                backgroundColor: 'rgba(0, 128, 128, 0.8)',
                data: bankValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    align: 'top',
                    anchor: 'end',
                    display: true,
                    formatter: function (value) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                }
            }
        ],
    }

    // const data1 = {
    //     labels: investmentLabels,
    //     datasets: [
    //         {
    //             backgroundColor: '#00FF7F',
    //             borderColor: '#00FF7F',
    //             label: 'Cash',
    //             data: investmentValues,
    //             datalabels: {
    //                 display: false,
    //             }
    //         },
    //         {
    //             backgroundColor: '#40E0D0',
    //             borderColor: '#40E0D0',
    //             label: 'LifeStrategy Equity Fund',
    //             data: investmentValues1,
    //             datalabels: {
    //                 display: false,
    //             }
    //         },
    //         {
    //             backgroundColor: '#D2B48C',
    //             borderColor: '#D2B48C',
    //             label: 'FTSE Developed Europe UCITS ETF (VEUR)',
    //             data: investmentValues2,
    //             datalabels: {
    //                 display: false,
    //             }
    //         },
    //         {
    //             backgroundColor: '#4682B4',
    //             borderColor: '#4682B4',
    //             label: 'S&P 500 UCITS ETF (VUSA)',
    //             data: investmentValues3,
    //             datalabels: {
    //                 display: false,
    //             }
    //         },
    //         {
    //             backgroundColor: '#FF7F50',
    //             borderColor: '#FF7F50',
    //             label: 'Global Equity Fund - Accumulation',
    //             data: investmentValues4,
    //             datalabels: {
    //                 display: false,
    //             }
    //         },
    //         {
    //             backgroundColor: '#B5DDD1',
    //             borderColor: '#B5DDD1',
    //             label: 'ESG North America All Cap',
    //             data: investmentValues5,
    //             datalabels: {
    //                 display: false,
    //             }
    //         },
    //     ],
    // }

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        // return number;
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);


    const tokenRefresh = useCallback(async () => {


        try {
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", `Bearer ${response.idToken}`);
        } catch (error) {
            console.error("Error acquiring token", error);
        }
    }, [accounts, instance]);


    const openModal = () => {
        setShowModal(!showModal)
    }

    const closeModal = () => {
        setShowModal(false);
        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);
        setLoading5(true);
        setToggleModal(!toggleModal)
    }
    const callRefresh = () => {
        // re-renders the component
        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);
        setLoading5(true);
        setRefreshPage(Date.now());
    };

    const budgetRefresh = useCallback(() => {

        const getFieldData = async () => {
            await base.getAll("Dashboard/FieldData").then((fieldRes) => {
                setFieldData(fieldRes);
                setLoading1(false);
            });
        }
        const getBudgetData = async () => {
            await base.getAll("Dashboard/Budget").then((budgetRes) => {
                setBudgetData(budgetRes);
                setLoading2(false);
            });
        }
        getFieldData();
        getBudgetData();
    }, []);

    const ButtonRenderer = useCallback((props) => {

        const handleTopUp = (direction) => {
            base.update(`Dashboard/topupbudget/${props.data.categoryId}/${direction}`).then(() => {
                budgetRefresh();
            });
        }
        return (
            <div style={{ display: "flex", gap: "5px" }}>
                <Button className="p-0" color="light" onClick={() => handleTopUp("up")}><IoAddCircle size={24} className="transaction-in-icon" /></Button>
                <Button className="p-0" color="light" onClick={() => handleTopUp("down")}><IoRemoveCircle size={24} className="transaction-out-icon" /></Button>
            </div>
        );
    }, [budgetRefresh]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([

            {
                headerName: "Category",
                field: "categoryId",
                suppressMovable: true,
                maxWidth: 95
            },
            {
                headerName: "Description",
                field: "categoryDescription",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Amount",
                field: "totalRemaining",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 125,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "Top-up",
                filter: false,
                maxWidth: 85,
                cellRenderer: ButtonRenderer,
                cellStyle: { display: 'flex', justifyContent: 'center' }
            },
        ]
        );

        setTransColDef(
            [{
                headerName: "Date",
                field: "transactionDate",
                suppressMovable: true,
                maxWidth: 125,
                valueGetter: (params) => {
                    const value = params.data.transactionDate;
                    if (typeof value === "string" && value.includes("T")) {
                        return value.split("T")[0];
                    }
                    return value;
                },
            },
            {
                headerName: "Category",
                field: "categoryId",
                suppressMovable: true,
                maxWidth: 125,

            },
            {
                headerName: "Description",
                field: "transactionDescription",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Account",
                field: "accountId",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Amount",
                field: "value",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 300,
                valueFormatter: currencyFormatter
            },

            ]
        );
    }, [currencyFormatter, ButtonRenderer]);



    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getFieldData = async () => {
            await base.getAll("Dashboard/FieldData").then((fieldRes) => {
                setFieldData(fieldRes);
                setLoading1(false);
            });
        }
        const getBudgetData = async () => {
            await base.getAll("Dashboard/Budget").then((budgetRes) => {
                setBudgetData(budgetRes);
                setLoading2(false);
            });
        }
        const getExpenseData = async () => {
            await base.getAll("Dashboard/expense?Month=6").then((expenseData) => {
                setExpData(expenseData);
                setLoading3(false);
            });
        }
        const getBankData = async () => {
            await base.getAll("Dashboard/Bank").then((bankRes) => {
                // setBankData(bankRes);
                setBankLabels(bankRes.map((item) => item.accountId));
                setBankValues(bankRes.map((item) => item.balance));
                setLoading4(false);
            });
        }
        const getInvestmentData = async () => {
            await base.getAll("Dashboard/Investment").then((investmentRes) => {
                const reorderedInvestmentRes = [
                    ...investmentRes.filter((item) => item.fundName !== "Cash"),
                    ...investmentRes.filter((item) => item.fundName === "Cash"),
                ];

                const totalFundValue = reorderedInvestmentRes
                    .map((item) => item.fundValue)
                    .reduce((a, b) => a + b, 0);

                const totalProfitValue = reorderedInvestmentRes
                    .map((item) => item.profitValue)
                    .reduce((a, b) => a + b, 0);

                const investmentTotal = reorderedInvestmentRes
                    .map((item) => item.investmentTotal)
                    .reduce((a, b) => a + b, 0);

                const profitPercentage = (totalProfitValue / (totalFundValue - totalProfitValue)) * 100;

                setInvestment({
                    data: reorderedInvestmentRes,
                    totalFundValue,
                    totalProfitValue,
                    investmentTotal,
                    profitPercentage: profitPercentage.toFixed(1),
                });

            }).finally(() => {
                setLoading5(false);
            });
        }

        tokenRefresh().then(() => {
            getFieldData();
            getExpenseData();
            getBudgetData();
            getBankData();
            getInvestmentData();
        })

    }, [toggleModal, accounts, tokenRefresh, refreshPage])

    const handleClick = () => {
        window.open("https://client.moneyhub.co.uk/#");
    };



    const quickFixExceeded = async () => {
        await base.update("Dashboard/quickfixbudget").then(() => {
            budgetRefresh();
        })
    }

    return (
        <div>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Dashboard
                    </Col>
                    <Col xs="6" className="text-end pe-4">
                        <Button size="sm" color="info" className="me-1 btn-text" onClick={() => callRefresh()}>
                            <IoRefreshOutline size={24} style={{ color: "#111" }} />
                        </Button>
                        <Button size="sm" color="dark" className="me-1 btn-text" onClick={() => openModal()}>
                            <IoCloudUploadOutline size={24} style={{ color: "#fff" }} />
                        </Button>
                        <Button size="sm" className="btn-text" onClick={handleClick}  >
                            <Moneyhub />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>

            <div className="pt-2 pb-2 res-padding scroll-container" style={{
                height: `calc(${viewportHeight}px - 180px)`,
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
            }}
            >
                <Row>
                    <Col xs="12" xl="5">
                        <Card className="mt-2">
                            <CardHeader className="cd-header">
                                <Row>
                                    <Col>
                                        <div className="d-flex">
                                            <IoWalletOutline size={24} style={{ color: "#111" }} />&nbsp;&nbsp;<div>Budgets</div>
                                        </div>
                                    </Col>
                                    <Col className="text-end">
                                        {!loading1 && fieldData.budgetExceeded + fieldData.unallocated !== 0 && (
                                            <Button size="sm" color="light" className="me-1 p-0 btn-text btn-border" onClick={() => quickFixExceeded()}>
                                                <IoHammerOutline size={20} style={{ color: "#111" }} />
                                            </Button>
                                        )
                                        }
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardHeader className="pt-1 pb-1 text-center">
                                {loading1 ?
                                    <LoadSpinner />
                                    :
                                    <>
                                        <Row>
                                            <Col className="text-muted cd-subheader">
                                                Budget
                                            </Col>
                                            <Col className="text-muted cd-subheader">
                                                Unallocated
                                            </Col>
                                            <Col className="text-muted cd-subheader">
                                                Exceeded
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2">
                                            <Col className="cd-value">
                                                {
                                                    fieldData.budgetTotal?.toLocaleString('GB-en', options)
                                                }
                                            </Col>
                                            <Col className="cd-value">
                                                {
                                                    fieldData.unallocated?.toLocaleString('GB-en', options)
                                                }
                                            </Col>
                                            <Col className="cd-value">
                                                {
                                                    fieldData.budgetExceeded?.toLocaleString('GB-en', options)
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </CardHeader>
                            <CardBody style={{ height: "1100px" }}>
                                {loading2 ?
                                    <LoadSpinner />
                                    :
                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                        <AgGridReact
                                            rowData={budgetData}
                                            columnDefs={budgetColDef}
                                            overlayNoRowsTemplate="No Records Found"
                                            defaultColDef={gridDefaultColDef}
                                            pagination={true}
                                            paginationPageSizeSelector={[10, 20, 50, 100]}
                                            paginationPageSize={100}>
                                        </AgGridReact>
                                    </div>

                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" xl="7">
                        <Row>
                            <Col xs="12">
                                <Card className="mt-2">
                                    <CardHeader className="cd-header">
                                        <div className="d-flex">
                                            <IoCardOutline size={24} style={{ color: "#111" }} />&nbsp;&nbsp;<div>Accounts</div>
                                        </div>
                                    </CardHeader>
                                    <CardHeader className="pt-1 pb-1 text-center">
                                        {loading1 ?
                                            <LoadSpinner />
                                            :
                                            <>
                                                <Row>
                                                    <Col className="text-muted cd-subheader">
                                                        Total Balance
                                                    </Col>
                                                    <Col className="text-muted cd-subheader">
                                                        Current Accounts
                                                    </Col>
                                                    <Col className="text-muted cd-subheader">
                                                        Credit Cards
                                                    </Col>
                                                    <Col className="text-muted cd-subheader">
                                                        Savings
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2">
                                                    <Col className="cd-value">
                                                        {
                                                            fieldData.bankTotal?.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>
                                                    <Col className="cd-value">
                                                        {
                                                            fieldData.ddTotal?.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>
                                                    <Col className="cd-value">
                                                        {
                                                            fieldData.ccTotal?.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>
                                                    <Col className="cd-value">
                                                        {
                                                            fieldData.saTotal?.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>

                                                </Row>
                                            </>
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        {loading4 ?
                                            <LoadSpinner />
                                            :
                                            <Bar data={data} style={{ maxHeight: "300px" }} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">

                                <Card className="mt-2">
                                    <CardHeader className="cd-header">
                                        <div className="d-flex">
                                            <IoGridOutline size={24} style={{ color: "#111" }} />&nbsp;&nbsp;<div>Transactions</div>
                                        </div>
                                    </CardHeader>

                                    <CardBody style={{ height: "400px" }}>
                                        {loading3 ?
                                            <LoadSpinner />
                                            :
                                            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                                <AgGridReact
                                                    rowData={expData}
                                                    columnDefs={transColDef}
                                                    pagination={true}
                                                    defaultColDef={gridDefaultColDef}
                                                    paginationPageSizeSelector={[10, 20, 50]}
                                                    paginationPageSize={10}
                                                    overlayNoRowsTemplate="No Records Found"
                                                >
                                                </AgGridReact>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">

                                <Card className="mt-2">
                                    <CardHeader className="cd-header">
                                        <div className="d-flex">
                                            <IoBarChartOutline size={24} style={{ color: "#111" }} />&nbsp;&nbsp;<div>Investments</div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {loading5 ? (
                                            <LoadSpinner />
                                        ) : (
                                            <>
                                                <Row className="pt-2 pb-2">
                                                    <Col xs="4" className="investment-total text-start" style={{ overflow: "ellipsis" }}>Fund Name</Col>
                                                    <Col xs="0" sm="2" className="investment-total text-end hide-xs">
                                                        Invested
                                                    </Col>
                                                    <Col xs="4" sm="2" className="investment-total text-end">
                                                        Fund Value
                                                    </Col>
                                                    <Col xs="4" sm="2" className="investment-total text-end">
                                                        Profit
                                                    </Col>
                                                    <Col xs="0" sm="2" className="investment-total text-end hide-xs">
                                                        Profit %
                                                    </Col>
                                                </Row>

                                                {investment.data.map((item, index) => (
                                                    <div key={index} className="investment-item">
                                                        <Row>
                                                            <Col xs="4" className="investment-name ellipsis" style={{ overflow: "hidden" }}>{item.fundName}</Col>
                                                            <Col xs="0" sm="2" className="investment-value text-end hide-xs">
                                                                {item.investmentTotal.toLocaleString('GB-en', { style: 'currency', currency: 'GBP' })}
                                                            </Col>
                                                            <Col xs="4" sm="2" className="investment-value text-end ">
                                                                {item.fundValue.toLocaleString('GB-en', { style: 'currency', currency: 'GBP' })}
                                                            </Col>
                                                            <Col xs="4" sm="2" className="investment-value text-end">
                                                                {item.profitValue.toLocaleString('GB-en', { style: 'currency', currency: 'GBP' })}
                                                            </Col>
                                                            <Col xs="0" sm="2" className="investment-value text-end hide-xs">
                                                                {item.profitPercent.toFixed(1)}%
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}
                                                <Row className="pt-2 pb-2">
                                                    <Col xs="4" className="investment-name" ></Col>
                                                    <Col xs="0" sm="2" className="investment-total text-end hide-xs">
                                                        {investment.investmentTotal?.toLocaleString('GB-en', options)}
                                                    </Col>
                                                    <Col xs="4" sm="2" className="investment-total text-end">
                                                        {investment.totalFundValue?.toLocaleString('GB-en', options)}
                                                    </Col>
                                                    <Col xs="4" sm="2" className="investment-total text-end">
                                                        {investment.totalProfitValue?.toLocaleString('GB-en', options)}
                                                    </Col>
                                                    <Col xs="0" sm="2" className="investment-total text-end hide-xs">
                                                        {`${investment.profitPercentage}%`}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}



                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div >


            {showModal && <FileModal closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} />}
        </div >

    );
}

export default Dashboard;
