const FormatDecimal = (value) => {
    // Round to 2 decimal places
    if (value === null || value === undefined || isNaN(value)) {
        return "0.00";
    }
    const [integer, decimal] = value.toFixed(2).split('.');
    // Manually format the integer part with commas
    let formattedInteger = '';
    let count = 0;
    // Process the integer part from the end to the beginning
    for (let i = integer.length - 1; i >= 0; i--) {
        formattedInteger = integer[i] + formattedInteger;
        count++;
        if (count % 3 === 0 && i !== 0) {
            formattedInteger = ',' + formattedInteger;
        }
    }
    // Return the formatted number with decimal part intact
    return `${formattedInteger}.${decimal}`;
}

const FormatInt = (value) => {
    // Round to 0 decimal places
    if (value === null || value === undefined || isNaN(value)) {
        return "0";
    }
    // Return the formatted number to 0 decimal places
    return value.toFixed(0);
}

export { FormatDecimal, FormatInt };
