import { Spinner, Col, Row } from "reactstrap"
import '../../Assets/css/Main.css';


const Loader = () => {
    return (
        <Row>
            <Col xs="12">
                <div className="loadspinner">
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </div>
            </Col>
        </Row>
    )
}

export default Loader


