import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Dashboard from '../Dashboard'
import Category from '../Category'
import Investment from '../Investment'
import Transactions from '../Transactions'
import Budget from '../Budget'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'

const PrivateRoute = ({ name }) => {

    return (
        <Router basename='/'>
            <Navbar />
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/budget" element={<Budget />} />
                <Route path="/category" element={<Category />} />
                <Route exact path='/investment' element={<Investment />} />
                <Route exact path='/transactions' element={<Transactions />} />
            </Routes>
            <Footer name={name} />

        </Router>
    )
}

export default PrivateRoute
