import React from 'react'
import { useState } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import base from '../Services/BaseService'

const BudgetModalCreate = ({ closeModal1, showModalForm1, setShowModal1, setSaveItem }) => {
    const periodValue = new Date().getFullYear() + '.' + ("0" + (new Date().getMonth() + 1)).slice(-2);
    const [pName, setPName] = useState(periodValue)
    const [showModal, setShowModal] = useState(showModalForm1)
    const data = {};

    const toggleModal = () => {
        closeModal1()
        setShowModal(showModal => !showModal);
        setShowModal1(!showModalForm1);
    }

    const updateBudget = () => {
        data.period = pName;
        base.update("Budget/Create", data).then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
    }

    const handleChange = (e) => {
        if (e.target.name === 'period') {
            setPName(e.target.value);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Create Budget Period</div></ModalHeader>
                <>
                    <ModalBody>
                        <FormGroup>
                            <Row className="mb-2 align-items-center">
                                <Col md="3" lg="2">
                                    <Label className="label-text" >Period*</Label>
                                </Col>
                                <Col md="9" lg="10">
                                    <Input type="text" className="input-text" name="period" id="period" placeholder="" defaultValue={periodValue} onChange={handleChange} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateBudget()}>Create</Button>
                    </ModalFooter>
                </>

            </Modal>
        </>
    )
}

export default BudgetModalCreate
