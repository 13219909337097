import React from 'react'
import { useEffect, useState, useCallback, useRef } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Card, CardBody } from 'reactstrap';
import { IoCloudDownloadOutline, IoFilterOutline, IoArrowDownCircle, IoArrowUpCircle } from 'react-icons/io5';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import Grid from './Shared/Grid';
import { ExportExcel } from "./Shared/ExportExcel";

const Transactions = () => {

    const gridRef = useRef();
    const [data, setData] = useState([]);
    const [originData, setOriginData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [inValue, setInValue] = useState(0);
    const [outValue, setOutValue] = useState(0);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [expLoading, setExpLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const keysRef = useRef([]);
    const [filters, setFilters] = useState({
        fromDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`, toDate: new Date().toISOString().split('T')[0], categoryId: '', transactionDescription: ''
    });

    const formatNumber = useCallback((number) => {
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
    }, [formatNumber]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([{
            headerName: "Date",
            field: "transactionDate",
            suppressMovable: true,
            maxWidth: 95,
            valueGetter: (params) => {
                const value = params.data.transactionDate;
                if (typeof value === "string" && value.includes("T")) {
                    return value.split("T")[0];
                }
                return value;
            },


        },
        {
            headerName: "Category",
            field: "categoryId",
            suppressMovable: true,
            maxWidth: 95,

        },
        {
            headerName: "Description",
            field: "transactionDescription",
            suppressMovable: true,
            maxWidth: 330
        },
        {
            headerName: "Account",
            field: "accountId",
            suppressMovable: true,
            maxWidth: 100
        },
        {
            headerName: "Amount",
            field: "value",
            suppressMovable: true,
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            maxWidth: 125,
            valueFormatter: currencyFormatter
        },
        ]);
    }, [currencyFormatter]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    const handleApplyFilters = () => {
        let filteredData = originData;
        if (filters.fromDate) {
            filteredData = filteredData.filter((item) => new Date(item.transactionDate) >= new Date(filters.fromDate));
        }
        if (filters.toDate) {
            filteredData = filteredData.filter((item) => new Date(item.transactionDate) <= new Date(filters.toDate));
        }
        if (filters.categoryId) {
            filteredData = filteredData.filter((item) => item.categoryId === filters.categoryId);
        }
        if (filters.transactionDescription) {
            filteredData = filteredData.filter((item) => item.transactionDescription.toLowerCase().includes(filters.transactionDescription.toLowerCase()));
        }
        setInValue(filteredData.filter((item) => item.value > 0 && item.categoryId !== 'AB').reduce((acc, item) => acc + item.value, 0));
        setOutValue(filteredData.filter((item) => item.value < 0 && item.categoryId !== 'AB').reduce((acc, item) => acc + item.value, 0));
        setData(filteredData);
        setShowModal(false);
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);


    const handleClearFilers = () => {
        setFilters({ fromDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`, toDate: new Date().toISOString().split('T')[0], categoryId: '', transactionDescription: '' });
        setData(originData);
        setShowModal(false);
    }

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", `Bearer ${response.idToken}`);
        }).catch(() => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getTransactionData = () => {
            base.getAll("Dashboard/expense?month=48").then((res) => {
                setData(res);
                setOriginData(res);
                keysRef.current = Object.keys(res[0]);
                setInValue(res.filter((item) => item.value > 0 && item.categoryId !== 'AB').reduce((acc, item) => acc + item.value, 0));
                setOutValue(res.filter((item) => item.value < 0 && item.categoryId !== 'AB').reduce((acc, item) => acc + item.value, 0));
            })
                .finally(() => {
                    setExpLoading(false)
                });
            base.getAll("Category").then((res) => {
                const categories = res.map((item) => ({
                    value: item.categoryId,
                    label: item.categoryDescription
                }));
                setCategoryData(categories);
            });
        }
        tokenRefresh().then(() => {
            getTransactionData();

        })

    }, [tokenRefresh])

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    return (
        <>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Transactions
                    </Col>
                    <Col xs="6" className="text-end pe-4" >
                        <Button size="sm" className="btn-text me-1" color="secondary" onClick={() => setShowModal(!showModal)} >
                            <IoFilterOutline size={24} style={{ color: "#fff" }} />
                        </Button>
                        <Button size="sm" className="btn-text" color="dark" onClick={() => ExportExcel(data, "Transactions", keysRef.current)}>
                            <IoCloudDownloadOutline size={24} style={{ color: "#fff" }} />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            {expLoading ?
                <LoadSpinner />
                :
                <>
                    <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                        <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Apply Filters</div></ModalHeader>

                        <ModalBody>
                            <Row>
                                <Col>
                                    <Label className="label-text">Description</Label>
                                    <Input className="input-text" value={filters.transactionDescription} name='transactionDescription' onChange={handleInputChange} type="text" />
                                </Col>
                                <Col>
                                    <Label className="label-text">Category</Label>
                                    <Input type="select" name="categoryId" value={filters.categoryId} className="input-text" onChange={handleInputChange}>
                                        <option value="">Select Category</option>
                                        {categoryData.map((item, index) => {
                                            return <option key={index} value={item.value}>{item.label}</option>
                                        }
                                        )}
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-text">From Date</Label>
                                    <Input className="input-text" name='fromDate' value={filters.fromDate} type="date" onChange={handleInputChange} />
                                </Col>
                                <Col>
                                    <Label className="label-text">To Date</Label>
                                    <Input className="input-text" name='toDate' value={filters.toDate} onChange={handleInputChange} type="date" />
                                </Col>
                            </Row>

                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" color="light" style={{ width: "100px" }} className="btn-text-dark" onClick={handleClearFilers}>Clear</Button>
                            <Button size="sm" color="dark" style={{ width: "100px" }} className="btn-text" onClick={handleApplyFilters}>Apply</Button>
                        </ModalFooter>
                    </Modal>
                    <div style={{ marginBottom: "80px" }}>
                        <div className="pt-2 pb-2 res-padding scroll-container">
                            <Row>
                                <Col xs="6" xl="2" className="">
                                    <Card>
                                        <CardBody className="p-3">
                                            <div className="d-flex justify-content-between w-100">
                                                {/* Left side for the icon */}
                                                <div className="d-flex align-items-center">
                                                    <IoArrowUpCircle className="me-2 transaction-in-icon" size={45} />
                                                </div>

                                                {/* Right side for "In" label and value */}
                                                <div className="d-flex flex-column align-items-end">
                                                    <div className="text-muted transaction-header">Incoming</div>
                                                    <div className="transaction-in">{formatNumber(inValue)}</div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xs="6" xl="2" className="">
                                    <Card>
                                        <CardBody className="p-3">
                                            <div className="d-flex justify-content-between w-100">
                                                {/* Left side for the icon */}
                                                <div className="d-flex align-items-center">
                                                    <IoArrowDownCircle className=" me-2 transaction-out-icon" size={45} />
                                                </div>
                                                {/* Right side for "In" label and value */}
                                                <div className="d-flex flex-column align-items-end">
                                                    <div className="text-muted transaction-header">Outgoing</div>
                                                    <div className="transaction-out">{formatNumber(outValue)}</div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Grid gridDefaultColDef={gridDefaultColDef} colDef={budgetColDef} data={data} gridRef={gridRef} >
                            </Grid>
                        </div>
                    </div >
                </>
            }
        </>
    )
}

export default Transactions
